//@ts-nocheck

import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainMenu from "./components/common/MainMenu";

import logo from "./assets/ai_long_logo.png";
import icon from "./assets/ai_short_logo.png";
import PrivateRoute from "./components/common/PrivateRoute";
import { useState, lazy, Suspense } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Divider, Layout, Spin } from "antd";

// pages import
const Authentication = lazy(() => import("./pages/Authentication/Login"));
const SignUp = lazy(() => import("./pages/Authentication/SignUp"));
const ForgotPassword = lazy(
  () => import("./pages/Authentication/ForgotPassword")
);
const VerifyEmail = lazy(() => import("./pages/Authentication/VerifyEmail"));
const Reports = lazy(() => import("./pages/Reports"));
const ManageAuth = lazy(() => import("./pages/Authentication/ManageAuth"));
const Auth = lazy(() => import("./pages/Authentication/Auth"));
const Dashboard = lazy(() => import("./pages/Dashboard"));

const { Sider, Content } = Layout;

function App(): JSX.Element {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <Router>
      <Suspense
        fallback={
          <Spin
            size="large"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          />
        }
      >
        <Switch>
          <Route path="/" exact component={Auth} />
          <Route path="/login" exact component={Authentication} />
          <Route path="/signup" exact component={SignUp} />
          <Route path="/forgotpassword" exact component={ForgotPassword} />
          <Route path="/verifyemail" exact component={VerifyEmail} />
          <Route path="/manageauth" exact component={ManageAuth} />
          <>
            <Layout>
              <Sider
                className="sider"
                onCollapse={() => setCollapsed(!collapsed)}
                trigger={
                  !collapsed ? (
                    <LeftOutlined className="menu-control" />
                  ) : (
                    <RightOutlined className="menu-control" />
                  )
                }
                collapsible
                collapsed={collapsed}
              >
                <div className={collapsed ? "icon" : "logo"}>
                  {!collapsed ? (
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: 150, height: 43.89 }}
                    />
                  ) : (
                    <img
                      src={icon}
                      alt="logo"
                      style={{ width: 40, height: 42.34 }}
                    />
                  )}
                </div>
                <Divider />
                <MainMenu viewMode="inline" />
              </Sider>
              <Layout>
                <Content className="content" id="content">
                  <Switch>
                    <PrivateRoute path="/map" exact component={Dashboard} />
                    <PrivateRoute path="/dashboard" exact component={Reports} />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
