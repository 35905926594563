import { createSlice, nanoid } from "@reduxjs/toolkit";
import { FlyToInterpolator } from "react-map-gl";
import { categories } from "../constants/categories";
import { DeckglTypes } from "./data";

const initialState: DeckglTypes = {
  mapStyle: {
    id: "dark",
    label: "Dark",
    url: "mapbox://styles/uberdata/cjoqbbf6l9k302sl96tyvka09",
    icon: `https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_DARK_V2.png`,
  },
  dataInfo: {},
  categories: categories,
  configlayers: {},
  objectInfo: {},
  viewState: {
    longitude: -1.415727,
    latitude: 52.232395,
    zoom: 6.5,
    minZoom: 2,
    maxZoom: 20,
    bearing: 0,
    pitch: 0,
    transitionDuration: 1000,
    transitionInterpolator: new FlyToInterpolator(),
  },
  filters: {},
  selectedLayers: [],
  analytics: {
    enabled: false,
    modeName: null,
    mode: null,
    features: [],
    selectedFeature: null,
    selectedFeatureIndex: null,
    deleteFeature: false,
  },
};

const deckglModel = createSlice({
  name: "deckgl",
  initialState,
  reducers: {
    clearDeckState: () => initialState,
    saveConfigLayers(state, { payload: { layers } }) {
      state.configlayers = {
        ...state.configlayers,
        ...layers,
      };
    },
    editConfigLayers(state, { payload: { layers, id } }) {
      state.configlayers = {
        ...state.configlayers,
        [id]: {
          ...layers,
        },
      };
    },
    deleteConfigLayer(state, { payload }) {
      const newConfigLayers = { ...state.configlayers };
      delete newConfigLayers[payload];
      state.configlayers = newConfigLayers;
    },
    addObjectInfo(state, { payload }) {
      state.objectInfo = payload;
    },
    addMapStyle(state, { payload }) {
      state.mapStyle = payload;
    },
    changeViewState(state, { payload }) {
      state.viewState = { ...state.viewState, ...payload };
    },
    editDataInfo(state, { payload }) {
      state.dataInfo = {
        ...state.dataInfo,
        [payload.id]: {
          ...payload.content,
        },
      };
    },
    deleteDataInfo(state, { payload }) {
      const dataInfo = { ...state.dataInfo };
      delete dataInfo[payload];
      state.dataInfo = dataInfo;
    },
    addFilter: {
      reducer(state, { payload: { id, data } }: any) {
        state.filters = { ...state.filters, [id]: data };
      },
      prepare() {
        return { payload: { id: nanoid(), data: {} } };
      },
    },
    setFilter(state, { payload }) {
      state.filters = { ...state.filters, ...payload };
    },
    deleteFilter(state, { payload }) {
      const filters = { ...state.filters };
      delete filters[payload];
      state.filters = filters;
    },
    addFilterDataId(state, { payload: { filterId, datasetId } }) {
      const filters = { ...state.filters };
      const newFilter = {
        ...filters,
        [filterId]: {
          [datasetId]: [
            {
              fieldId: nanoid(),
              data: {
                fieldBasedOn: "",
                values: [],
                range: [],
                minmax: [],
              },
            },
          ],
        },
      };
      state.filters = newFilter;
    },
    addFilterField(state, { payload: { filterId, datasetId } }) {
      const filters = { ...state.filters };
      const newFilter = {
        ...filters,
        [filterId]: {
          [datasetId]: [
            ...filters[filterId][datasetId],
            {
              fieldId: nanoid(),
              data: {
                fieldBasedOn: "",
                values: [],
                range: [],
                minmax: [],
              },
            },
          ],
        },
      };
      state.filters = newFilter;
    },
    deleteFilterField(state, { payload: { filterId, datasetId, fieldId } }) {
      const filters = { ...state.filters };
      const newFilters = {
        ...filters,
        [filterId]: {
          [datasetId]: filters[filterId][datasetId].filter(
            (item: any) => item.fieldId !== fieldId
          ),
        },
      };
      state.filters = newFilters;
    },
    updateFilterFieldData(
      state,
      {
        payload: { filterId, datasetId, fieldId, value, range, minmax, values },
      }
    ) {
      const filters = { ...state.filters };
      const newFilters = {
        ...filters,
        [filterId]: {
          [datasetId]: filters[filterId][datasetId].map((field: any) =>
            field.fieldId === fieldId
              ? {
                  fieldId: fieldId,
                  data: {
                    fieldBasedOn: value,
                    values: values,
                    range: range,
                    minmax: minmax,
                  },
                }
              : field
          ),
        },
      };
      state.filters = newFilters;
    },
    updateSelectedLayers(state, { payload: { data, type } }) {
      if (type === "click") {
        state.selectedLayers = data;
      } else if (type === "upload") {
        state.selectedLayers.push(data);
      } else {
        state.selectedLayers = Array.from(
          new Set([...state.selectedLayers, data])
        );
      }
    },
    editAnalytics(state, { payload }) {
      state.analytics = { ...state.analytics, ...payload };
    },
    resetMapState(state) {
      state.dataInfo = {};
      state.configlayers = {};
      state.objectInfo = {};
      state.filters = {};
      state.selectedLayers = [];
    },
  },
});

export const {
  clearDeckState,
  saveConfigLayers,
  editConfigLayers,
  deleteConfigLayer,
  addObjectInfo,
  addMapStyle,
  changeViewState,
  editDataInfo,
  deleteDataInfo,
  addFilter,
  setFilter,
  deleteFilter,
  addFilterDataId,
  addFilterField,
  deleteFilterField,
  updateFilterFieldData,
  updateSelectedLayers,
  editAnalytics,
  resetMapState,
} = deckglModel.actions;
export default deckglModel.reducer;
