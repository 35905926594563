import { createSlice } from "@reduxjs/toolkit";
import { globalType } from "./data";

const initialState: globalType = {
  activePage: "",
  pageIndex: null,
  error: {
    status: 0,
    message: "",
  },
};

const globalModel = createSlice({
  name: "global",
  initialState,
  reducers: {
    queryActivePage(state, { payload: { pageName, index } }) {
      state.activePage = pageName;
      state.pageIndex = index;
    },
    raiseError(state, { payload }) {
      state.error.status = payload.status;
      state.error.message = payload.message;
    },
  },
});

export const { queryActivePage, raiseError } = globalModel.actions;
export default globalModel.reducer;
