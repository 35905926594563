import { Avatar, Button, Menu, message, Popover, Space } from "antd";
//@ts-ignore
import { Link, useHistory } from "react-router-dom";
import {
  DashboardOutlined,
  GlobalOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { batch, useDispatch, useSelector } from "react-redux";
import { clearDeckState, clearUser, queryActivePage } from "../../model";
import { auth } from "../../services/firebase";
import { StateTypes } from "../../model/data";

const menu = [
  { id: 1, name: "Map", icon: <GlobalOutlined /> },
  { id: 2, name: "Dashboard", icon: <DashboardOutlined /> },
  { id: 3, name: "Help", icon: <QuestionCircleOutlined /> },
  { id: 4, name: "Settings", icon: <SettingOutlined /> },
];

const MainMenu = ({ viewMode }: any) => {
  const {
    user: {
      currentUser: { displayName, email, photoURL },
    },
    global: { pageIndex },
  } = useSelector((state: StateTypes) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const signout = async () => {
    auth
      .signOut()
      .then(() => {
        batch(() => {
          dispatch(clearUser());
          dispatch(clearDeckState());
          dispatch(queryActivePage({ pageName: "/", index: 0 }));
        });
      })
      .then(() => history.push("/"))
      .catch((err) => {
        message.error({ content: "Internal error occurred!" });
      });
  };

  const handleNavigation = (item: any) => {
    dispatch(queryActivePage({ pageName: item.name, index: item.id }));
  };

  return (
    <Menu
      theme="dark"
      mode={viewMode}
      defaultSelectedKeys={pageIndex ? [pageIndex.toString()] : ["1"]}
      style={{ width: "100%", height: "auto", justifyContent: "center" }}
    >
      {menu.map((item: any) => {
        if (item.name === "Settings") {
          return (
            <Popover
              key={item.id}
              trigger={["click"]}
              placement="rightBottom"
              title={
                <Space style={{ padding: "8px 0px", width: "100%" }}>
                  <Avatar src={photoURL} icon={<UserOutlined />} />
                  {displayName || email}
                </Space>
              }
              content={
                <Button
                  block
                  type="text"
                  onClick={signout}
                  icon={<LogoutOutlined />}
                >
                  Sign out
                </Button>
              }
              className="user-control-popup"
            >
              <Menu.Item
                key={item.id}
                icon={item.icon}
                title={item.name}
                style={{ fontSize: 16 }}
                className="user-control"
              >
                <Link to="#" title="">
                  {item.name}
                </Link>
              </Menu.Item>
            </Popover>
          );
        } else if (item.name === "Help") {
          return (
            <Menu.Item
              key={item.id}
              icon={item.icon}
              style={{
                fontSize: 16,
              }}
              className="user-help"
            >
              <a
                href="https://leouserfile.s3.eu-central-1.amazonaws.com/User+Guidelines+V1.4.pdf"
                rel="noreferrer"
                download="User Guidelines.pdf"
                target="_blank"
                title=""
              >
                {item.name}
              </a>
            </Menu.Item>
          );
        } else {
          return (
            <Menu.Item
              key={item.id}
              icon={item.icon}
              onClick={() => handleNavigation(item)}
              title={item.name}
              style={{ fontSize: 16 }}
            >
              <Link to={`/${item.name.toLowerCase()}`} title="">
                {item.name}
              </Link>
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );
};

export default MainMenu;
