export const categories = [
  {
    id: "SF123",
    key: "SF123",
    title: "Street Furniture",
    checkable: true,
    children: [
      {
        id: "SF1",
        key: "04a2d947-4c94-4da9-be44-88121bd0c006",
        title: "Streetlamps",
        path: "SF123.SF1",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
    ],
  },
  {
    id: "EVC123",
    key: "EVC123",
    title: "EV Chargers",
    checkable: true,
    children: [
      {
        id: "EVC1",
        key: "f93b1e42-4175-429a-960f-56e40cc9b4eb",
        title: "Existing EV Chargepoints",
        path: "EVC123.EVC1",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
    ],
  },
  {
    id: "BE123",
    key: "BE123",
    title: "Built Environment",
    checkable: true,
    children: [
      {
        id: "BE1",
        key: "f1e36d20-db88-4bea-a870-786f40d51eb5",
        title: "Pavement Width",
        path: "BE123.BE1",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "BE2",
        key: "BE2",
        title: "Building stock",
        path: "BE123.BE2",
        checkable: true,
        children: [
          {
            id: "BE2A",
            key: "8c6b1e08-5f94-4c5d-a43b-8a930af74dac",
            path: "BE123.BE2.BE2A",
            title: "All buildings",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "BE2B",
            key: "3fb122ec-65c2-4b74-ab24-7bc89e0e56ee",
            path: "BE123.BE2.BE2B",
            title: "Schools",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "BE2C",
            key: "1a4daf2a-e853-4e1a-8cb8-62b3baf2393a",
            path: "BE123.BE2.BE2C",
            title: "Council owned offices",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
        ],
      },
      {
        id: "BE3",
        key: "351d59d6-6d92-49ca-ac58-b2477fe44dc1",
        title: "Council owned parks and other land",
        path: "BE123.BE3",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
    ],
  },
  {
    id: "EN123",
    key: "EN123",
    title: "Electricity Network",
    checkable: true,
    children: [
      {
        id: "EN1",
        key: "EN1",
        title: "Primary substations",
        path: "EN123.EN1",
        checkable: true,
        children: [
          {
            id: "EN1A",
            key: "6ef265b6-664f-4c98-a800-417a13013394",
            path: "EN123.EN1.EN1A",
            title: "Substation supply areas & capacity",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "EN1B",
            key: "ee69fce0-e5c7-43c4-aa0a-6be4194d0d41",
            path: "EN123.EN1.EN1B",
            title: "EV connections by substation",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
        ],
      },
      {
        id: "EN2",
        key: "EN2",
        title: "Secondary Substations",
        path: "EN123.EN2",
        checkable: true,
        children: [
          {
            id: "EN2A",
            key: "de9035b9-9702-40e4-9365-71ab02c78c25",
            path: "EN123.EN2.EN2A",
            title: "Substation point locations",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "EN2B",
            key: "3e4c8edc-cf65-4d83-a3eb-7f9c94cc26fc",
            path: "EN123.EN2.EN2B",
            title: "Substation supply areas & capacity",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
        ],
      },
      {
        id: "EN3",
        key: "EN3",
        title: "Low Voltage Assets",
        path: "EN123.EN3",
        checkable: true,
        children: [
          {
            id: "EN3A",
            key: "7dfccdc8-c41a-44df-9656-2e4659f4b144",
            path: "EN123.EN3.EN3A",
            title: "Cabinet feeder location",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "EN3B",
            key: "1ab78d28-27bf-4989-8c40-55dec31d1df7",
            path: "EN123.EN3.EN3B",
            title: "Connector location",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "EN3C",
            key: "017d0243-1999-4fbc-a41a-559773a51873",
            path: "EN123.EN3.EN3C",
            title: "Isolating equipment",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "EN3D",
            key: "5aa860a3-ff06-4177-ac99-967da2ebc472",
            path: "EN123.EN3.EN3D",
            title: "Pole location",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "EN3E",
            key: "0658f9b9-17d5-41ca-81fb-9d3a83d0d4db",
            path: "EN123.EN3.EN3E",
            title: "Tower location",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "EN3F",
            key: "02bf1920-8e5a-4b48-8840-16b9cfddbb76",
            path: "EN123.EN3.EN3F",
            title: "Cable segments",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "EN3G",
            key: "ec0124ea-59c9-4ac1-a5c7-74add9705e09",
            path: "EN123.EN3.EN3G",
            title: "Wire Segments",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: "EL123",
    key: "EL123",
    title: "Environmental Layers",
    checkable: true,
    children: [
      {
        id: "EL1",
        key: "9daed513-a2c9-4acb-baa1-025c4a958d09",
        title: "Flood risk zones",
        path: "EL123.EL1",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "EL2",
        key: "eeb42326-aa22-44e1-8c01-005957e48772",
        title: "Protected Trees",
        path: "EL123.EL2",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
    ],
  },
  {
    id: "TID123",
    key: "TID123",
    title: "Transport Infrastructure data",
    checkable: true,
    children: [
      {
        id: "TID1",
        key: "2b2858f1-0800-4062-bb99-d881f164e86e",
        title: "Car Parks point",
        path: "TID123.TID1",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "TID2",
        key: "f097624b-248f-4991-bb95-9a1dd043007a",
        title: "Parking polygons",
        path: "TID123.TID2",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "TID3",
        key: "b8e4c221-e1d2-4fa2-87ac-273e0db18bd0",
        title: "Offstreet Parking",
        path: "TID123.TID3",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "TID4",
        key: "4b82224d-7edf-42fa-a6ee-5adea17e94d6",
        title: "Taxi ranks",
        path: "TID123.TID4",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "TID5",
        key: "7696950b-7a38-444a-9626-73db4fc741a6",
        title: "Train stations",
        path: "TID123.TID5",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
    ],
  },
  {
    id: "MD123",
    key: "MD123",
    title: "Mobility Data",
    checkable: true,
    children: [
      {
        id: "MD1",
        key: "MD1",
        title: "Existing public transport corridors",
        path: "MD123.MD1",
        checkable: true,
        children: [
          {
            id: "MD1A",
            key: "0899e972-91ab-4a34-a503-185227db646e",
            path: "MD123.MD1.MD1A",
            title: "Bus routes",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "MD1B",
            key: "19b4d8fd-3591-42f0-a3a3-d837dfdd0faf",
            path: "MD123.MD1.MD1B",
            title: "Cycle routes",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "MD1C",
            key: "a9cfc046-ec69-43dd-8096-9a4b188d17f2",
            path: "MD123.MD1.MD1C",
            title: "Train routes",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
        ],
      },
      {
        id: "MD2",
        key: "29186036-e5c7-4aed-884f-1f4443c4ac3a",
        title: "Ptals/Gmals",
        path: "MD123.MD2",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "MD3",
        key: "MD3",
        title: "Existing public transport corridors",
        path: "MD123.MD3",
        checkable: true,
        children: [
          {
            id: "MD3A",
            key: "2917fdf3-3f36-49fc-ab42-38f448601818",
            path: "MD123.MD3.MD3A",
            title: "Traffic 2019",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "MD3B",
            key: "e16d8a40-a383-4957-ae38-b008412795e2",
            path: "MD123.MD3.MD3B",
            title: "Traffic 2020",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
        ],
      },
      {
        id: "MD4",
        key: "fe066675-8781-48ae-95c8-ee9287fd3adb",
        title: "Average commute by LSOA",
        path: "MD123.MD4",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
    ],
  },
  {
    id: "SDD123",
    key: "SDD123",
    title: "Socio-demographic data",
    checkable: true,
    children: [
      {
        id: "SDD1",
        key: "SDD1",
        title: "Mosaic classification data",
        path: "SDD123.SDD1",
        checkable: true,
        children: [
          {
            id: "SDD1A",
            key: "2b4a891c-bb65-4c36-a119-d6ec3d29ee30",
            path: "SDD123.SDD1.SDD1A",
            title: "Average number of cars per household by postcode",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "SDD1B",
            key: "4824fe54-deb9-40a4-bf38-743a6fb368b8",
            path: "SDD123.SDD1.SDD1B",
            title: "EV Ownership",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "SDD1C",
            key: "a10028b7-db38-4b1c-b9f4-cada146294d2",
            path: "SDD123.SDD1.SDD1C",
            title: "Predicted EV uptake by postcode/LSOA/MSOA",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "SDD1D",
            key: "e512c090-9033-436b-903a-8c9c8d376870",
            path: "SDD123.SDD1.SDD1D",
            title: "Age by postcode",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "SDD1E",
            key: "a8a6863b-19aa-43c3-a5be-48a8ecd6f05e",
            path: "SDD123.SDD1.SDD1E",
            title: "Household income by postcode",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
          {
            id: "SDD1F",
            key: "6fffbf52-54f8-4e55-a471-f22be4b6c084",
            path: "SDD123.SDD1.SDD1F",
            title: "Employment by postcode",
            isLeaf: true,
            checkable: true,
            icon: () => null,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: "MDL123",
    key: "MDL123",
    title: "Modelled data layers",
    checkable: true,
    children: [
      {
        id: "MDL1",
        key: "66876c87-e7ff-4236-a2d2-8c8ad7ff2fba",
        title: "Utilisation prediction",
        path: "MDL123.MDL1",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "MDL2",
        key: "cef3071a-ba92-4457-9eac-958b6180dbbd",
        title: "Shared Mobility Score",
        path: "MDL123.MDL2",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
      {
        id: "MDL3",
        key: "c0e4aed3-51ca-49be-899d-9803ba7ef364",
        title: "Public transport accesibility score (PTALS/GMALS)",
        path: "MDL123.MDL3",
        checkable: true,
        isLeaf: true,
        icon: () => null,
        children: [],
      },
    ],
  },
];
