import { createSlice } from "@reduxjs/toolkit";
import { ReportTypes } from "./data";

const initialState: ReportTypes = {
  reportList: [],
  currentReportTab: 0,
  loading: false,
  reportModalVisible: false,
  chartModalVisible: false,
  fetched: false,
};

const reportModel = createSlice({
  name: "reports",
  initialState,
  reducers: {
    addReport(state, { payload }) {
      state.reportList.push({
        title: payload.title,
        description: payload.description,
        content: [],
        key: payload.key.toString(),
      });
      state.currentReportTab = state.reportList.length - 1;
    },
    deleteReport(state, { payload }) {
      const filteredReports = state.reportList.filter(
        (report) => report.key !== payload
      );
      const newReports = filteredReports.map((report, i) => ({
        key: i.toString(),
        title: report.title,
        content: report.content,
        description: report.description,
      }));
      state.reportList = newReports;
    },
    setReports(state, { payload }) {
      state.reportList = payload.reportList;
      state.currentReportTab = payload.currentReportTab;
    },
    setCurrentReportTab(state, { payload }) {
      state.currentReportTab = payload;
    },
    addChart(state, { payload }) {
      const reportIndex = Number(state.currentReportTab);
      const currentReport = state.reportList[reportIndex];
      const chart = {
        ...payload,
        key: currentReport.content.length,
        title: payload.chartType
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str: string) => str.toUpperCase()),
        xAxis: "",
        yAxis: [],
        applyFilter: false,
      };
      state.reportList[reportIndex].content.push(chart);
    },
    deleteChart(state, { payload }) {
      const reportIndex = Number(state.currentReportTab);
      const currentReport = state.reportList[reportIndex];
      currentReport.content = currentReport.content.filter(
        (chart: any) => chart.key !== payload
      );
      currentReport.content = currentReport.content.map(
        (chart: any, i: Number) => ({
          ...chart,
          key: i.toString(),
        })
      );
      state.reportList[reportIndex] = currentReport;
    },
    updateChart(state, { payload }) {
      const { chartKey, keyToUpdate, valueToUpdate } = payload;
      const reportIndex = state.currentReportTab;
      const currentReport = state.reportList[reportIndex];
      const currentChart = currentReport.content[chartKey];
      currentChart[keyToUpdate] = valueToUpdate;
      if (keyToUpdate === "datasetId") {
        currentChart["xAxis"] = "";
        currentChart["yAxis"] = [];
      }
      currentReport.content[chartKey] = currentChart;
      state.reportList[reportIndex] = currentReport;
    },
    toggleReportModal(state, { payload }) {
      state.reportModalVisible = payload;
    },
    toggleChartModal(state, { payload }) {
      state.chartModalVisible = payload;
    },
    toggleLoading(state, { payload }) {
      state.loading = payload;
    },
    toggleReportFetch(state, { payload }) {
      state.fetched = payload;
    },
  },
});

export const {
  addReport,
  deleteReport,
  setReports,
  toggleReportModal,
  toggleChartModal,
  setCurrentReportTab,
  addChart,
  deleteChart,
  updateChart,
  toggleLoading,
  toggleReportFetch,
} = reportModel.actions;

export default reportModel.reducer;
