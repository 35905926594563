//@ts-nocheck
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PrivateRoute({ component: Component, ...rest }: any) {
  const { loggedIn } = useSelector((state: Record<string, any>) => state.user);
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return loggedIn ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  );
}
