import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// import firebase config
import firebaseConfig from "../utils/firebase.config";

// initialize firebase auth
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const db = firebase.firestore();

// google provider
export const googleProvider = new firebase.auth.GoogleAuthProvider();
