import { createSlice } from "@reduxjs/toolkit";
import { NotificationType, CurrentUser } from "./data";

export type userModelState = {
  currentUser: CurrentUser;
  loggedIn: boolean;
  data: Record<string, any>;
  notification: NotificationType[];
  newUser: boolean;
};

const initialState: userModelState = {
  currentUser: {},
  loggedIn: false,
  data: {},
  notification: [],
  newUser: false,
};

const userModel = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser(state, { payload }) {
      state.currentUser = payload;
      state.loggedIn = true;
      state.notification = [];
    },
    saveCurrentUserWithFolderName(state, { payload }) {
      state.currentUser = payload;
      state.loggedIn = true;
    },
    saveData(state, { payload }) {
      state.data = payload;
    },
    // saveDataset(state, { payload: { datasetId, data } }) {
    //   state.dataset = { ...state.dataset, [datasetId]: data };
    // },
    updateData(state, { payload: { datasetId, data } }) {
      state.data[datasetId] = data;
    },

    addNotification(state, { payload }) {
      const newNotification = [...state.notification, payload];
      state.notification = newNotification;
    },
    updateNotification(state, { payload }) {
      const { id, status, message, createdAt } = payload;
      state.notification = [
        ...state.notification.map((notification: any) =>
          notification.id === id
            ? { id, status, message, createdAt }
            : notification
        ),
      ];
    },
    deleteNotification(state, { payload }) {
      const notification = [...state.notification].filter(
        ({ id }) => id !== payload
      );
      state.notification = notification;
    },
    deleteAllNotification(state, { payload }) {
      state.notification = payload;
    },
    clearUser(state) {
      state.currentUser = {};
      state.loggedIn = false;
      state.data = {};
      window.localStorage.clear();
    },
    newUserChange(state, { payload }) {
      state.newUser = payload;
    },
    updateCurrentUser(state, { payload }) {
      state.currentUser.regionId = payload;
    },
  },
});

export const {
  saveUser,
  clearUser,
  saveData,
  updateCurrentUser,
  updateData,
  saveCurrentUserWithFolderName,
  addNotification,
  updateNotification,
  deleteNotification,
  deleteAllNotification,
  newUserChange,
} = userModel.actions;
export default userModel.reducer;
